<script>
import Question from '@/components/flow/Question.vue'

export default {
  name: 'FlowSkinCheck',
  components: {
    Question
  },
  data(){
    return {
      is_first_view: true,
      IMAGE_ROOT_URL: process.env.VUE_APP_IMAGE_ROOT
    }
  },
  methods:{
    startFlow(){
      this.is_first_view=false;
    }
  }
}
</script>

<template>
  <main>
    <section
      v-if="is_first_view"
      class="skinCheck"
    >
      <div class="skinCheck_container">
        <div>{{ $t('flowSkinCheckSubTitle') }}</div>
        <h1>{{ $t('categorySkinCheck') }}</h1>
        <div class="skinCheck_explanation">
          {{ $t('flowSkinCheckDesc') }}
        </div>
        <a
          class="skinCheck_btn"
          @click="startFlow"
        >
          START
        </a>
      </div>
    </section>
    <section
      v-if="is_first_view"
      class="supervisor_container"
    >
      <h2>{{ $t('flowSkinCheckSupervisor') }}</h2>
      <div class="supervisor">
        <img
          :src="
            IMAGE_ROOT_URL
              + '/face-icon/hamano.png'
          "
        >
        <div class="supervisor_profile_container">
          <h3>{{ $t('hamanoName') }}</h3>
          <div class="supervisor_profile">
            <p>{{ $t('hamanoTitle') }}</p>
            <p>{{ $t('hamanoAffiliatedEx') }}</p>
            <p>{{ $t('hamanoAffiliated6') }}</p>
          </div>
          <div class="profile_btn_container">
            <router-link
              :to="{name: 'Concierges', params: {'incomingGenre': 'skincheck'}}"
              class="profile_btn"
            >
              {{ $t('conciergeSeeProfile') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <question
      v-if="!is_first_view"
      series="skin-type-diag"
    />
  </main>
</template>

<style scoped>
.skinCheck {
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 0 40px;
    color: #777;
}
.skinCheck_container {
    padding: 45px 0 20px 0;
}
.skinCheck_explanation {
    padding-bottom: 57px;
    text-align: left;
}
.skinCheck_btn {
    font-size: 24px;
    font-weight: bold;
    display: block;
    width: 90%;
    margin: 0 auto;
    border-radius: 100vh;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #fff;
}
.supervisor_container{
  padding: 80px 4px 60px 4px;
}
.supervisor {
    display: flex;
    align-items: center;
}
.supervisor p {
  font-size: 14px;
}
h1 {
    font-size: 24px;
    font-weight: bold;
    color: #F29F8F;
    padding: 12px 0 28px 0;
}
h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 20px;
}
h3 {
    font-size: 20px;
    text-align: center;
    color: #222;
}
.profile_btn{
    color: #777;
    background-color: #FADAD2;
    padding: 10px;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
}
.profile_btn_container{
  text-align: center;
}
.supervisor img {
  border-radius: 50%;
  width: 120px;
  border: solid 1px #ccc;
  margin: 0 10px;
}
.supervisor_profile_container{
  margin-left: 6px;
}
.supervisor_profile {
  padding: 10px 0;
}
.supervisor_profile p:nth-of-type(1),.supervisor_profile p:nth-of-type(2){
  padding-bottom: 5px;
}
.skinCheck p {
    text-align: left;
}
</style>
